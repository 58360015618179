import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Banner from "../../Component/Banner";
import {Container,Row,Col} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'; // Link componentini import edin





const CikmisSorular = () => {
    const [kategoriler, setKategoriler] = useState([]);


    useEffect(() => {
        // API adresinizi buraya girin
        const apiUrl = "https://panel.ehliyetyolda.com/api/data/sorukategoriler";

        // Verileri API'den çekme
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setKategoriler(data);
            })
            .catch((error) => {
                console.error("API'den veri alınırken bir hata oluştu:", error);
            });
    }, []); // Boş array ile useEffect'i sadece component mount edildiğinde çalıştır






    return (
        <div>
            <Helmet>
                <title>Çıkmış Sorular</title>
            </Helmet>
            <Banner title="Çıkmış Sorular"/>

            <Container>
                <h2 className="mt-4 mb-2">Çıkmış Sorular</h2>
                <Row>
                    <Col md={5}>
                        <ListGroup>
                            {kategoriler.map((kategori) => (
                                <ListGroup.Item key={kategori.id}>
                                    {/* ListGroup.Item içinde Link kullanımı */}
                                    <Link to={`/soru-coz/${kategori.id}`}>
                                        {kategori.baslik}
                                    </Link>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>


            </Container>



        </div>
    );
};

export default CikmisSorular;
