import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaInstagram,FaFacebook,FaTwitter } from 'react-icons/fa'; // Instagram ikonunu içe aktar



const Topbar = () => {
    return (
        <div style={{ backgroundColor: '#023d62', color: '#fff', padding: '10px 0' }}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={6} md={8}>
                        <p  style={{ margin: 0,fontSize:18,fontWeight:'bold' }}>Ehliyet Yolda</p>
                    </Col>
                    <Col xs={6} md={4} className="d-flex justify-content-end">
                        <Nav>
                            <Nav.Link href="https://instagram.com/yourusername" style={{ color: '#fff' }}>
                                <FaInstagram size="1.5em" style={{ marginRight: '10px' }}/>
                                <FaTwitter size="1.5em" style={{ marginRight: '10px' }}/>
                                <FaFacebook size="1.5em"/>
                            </Nav.Link>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Topbar;
